
@import "../../styles/variables.module";

.modalContainer{
    max-width: 656px;
    text-align: center;
    .modalBody {
        position: relative;
        .closeModal {
            position: absolute;
            top: -30px;
            right: -30px;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            padding: 0;
            border: none;
            font-size: 18px;
            transform: rotate(45deg);
        }
        .dialogInner {
            h3 {
                font-size: 28px;
                line-height: 40px;
                margin: 0;
            }
            p {
                margin-top: 15px;
                font-size: 16px;
                line-height: 28px;
            }
            .frmOuter {
                max-width: 22.5em;
                margin: 1.33em auto 0;
                input {
                    border: 1px solid #ABAAAA;
                    border-radius: 7px;
                    color: #b3b3b3;
                    background-color: rgba(255, 255, 255, 1);
                    height: 41px;
                    font-size: 14px;
                    &::placeholder {
                        font-size: 14px;
                        color: #b3b3b3;
                    }
                }
                button {
                    color: #FFFFFF;
                    background-color: #22b38c;
                    border: 0px solid #22b38c;
                    min-height: 41px;
                    border-radius: 7px;
                    width: 100%;
                    padding: 0 15px;
                    .spinner-border {
                        width: 22px;
                        height: 22px;
                        vertical-align: middle;
                        border-color: #fbfbfb;
                        border-right-color: #22b38c;
                    }
                }
                .pError {
                    width: 100%;
                    margin-top: 0.25rem;
                    font-size: .875em;
                    color: #dc3545;
                    text-align: left;
                }
            }
            .btnNoThanks {
                font-size: 16px;
                line-height: 28px;
                margin-top: 15px;
                border: none;
                background-color: transparent;
                color: $white-color;
            }
        }
        .responseWrap {
            h3 {
                font-size: 28px;
                line-height: 40px;
                margin: 0;
                color: $white-color;
            }
            button {
                max-width: 22.5em;
                margin: 1.33em auto 0;
                min-height: 41px;
                border-radius: 7px;
                width: 100%;
                padding: 0 15px;
                text-transform: uppercase;
                background-color: $secondary-color;
                color: $theme-color;
                height: initial;
                border: none;
                font-weight: 500;

                &:hover, &:focus {
                    background-color: #ffe915;
                }
            }
        }
    }
}